// import 'regenerator-runtime/runtime'
import Collapse from '../node_modules/bootstrap/js/src/collapse.js'
const nav = document.getElementById('navbarNav')
const navCol = new Collapse(nav, { toggle: false })
for (const a of nav.querySelectorAll('a')) {
  a.addEventListener('click', () => {
    navCol.hide()
  })
}

const installCol = new Collapse(document.querySelector('#install .collapse'), {
  toggle: false
})
document.querySelector('#install input').addEventListener('change', e => {
  e.target.disabled = true
  installCol.show()
})
document.querySelector('.pagetop').addEventListener('click', e => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})
document.addEventListener('submit', async e => {
  e.preventDefault()
  const formData = new FormData(e.target)
  grecaptcha.ready(async () => {
    const token = await grecaptcha.execute(
      '6Lc4vswZAAAAANU8CskYKyQV7azhxWVjHC3JyKvk',
      { action: 'submit' }
    )
    formData.append('recaptcha_token', token)
    const body = new URLSearchParams(formData)
    const res = await fetch('/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Length': body.length
      },
      body
    })
    const json = await res.json()
    if (json.ok) {
      alert('メッセージを受け付けました。折り返しご連絡差し上げます。')
    } else {
      alert('メッセージを送信できませんでした。')
    }
  })
})
